import React, { ReactElement, Suspense, useState, useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import clsx from 'clsx'
import browserLang from 'browser-lang'
import { defaultLang, fallbackLang, availableLang } from '@system/translations'
import isbot from 'isbot'
import { makeStyles } from '@material-ui/core/styles'
import Header from '@components/modules/global/header'
import Footer from '@components/modules/global/footer'
import MetaTags from '@components/modules/global/metatags'
import PageTransition from '@components/core/pageTransition'

const IntroAnimation = React.lazy(
  () => import('@components/modules/content/M008-IntroAnimation')
)

const useStyles = makeStyles((theme) => ({
  layoutRoot: {
    display: 'flex',
    flexDirection: 'column',
    jusifyContent: 'flex-start',
    alignItems: 'stretch',
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  layoutMain: {
    flex: '1 0 auto',
  },
  layoutMainHidde: {
    opacity: 0,
  },
  introFallback: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.main,
    width: '100vw',
    height: '100vh',
    zIndex: 51,
  },
}))

interface INavNodeQuery {
  node_locale: string
  pages?: Array<INavItemQuery>
}

interface INavItemQuery {
  __typename: string
  titleNavigation: string
  fields: {
    fullPath?: string
    isExternal?: boolean
  }
}

export type LayoutProps = {
  children: React.ReactNode
  pageContext: DBN.PageHelpers.PageContext
}

export default function Layout({
  children,
  pageContext,
}: LayoutProps): ReactElement {
  const classes = useStyles()
  const { mainNav, footerNav } = useStaticQuery(graphql`
    query {
      mainNav: allContentfulNavigation(
        filter: { identifier: { eq: "main-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
          }
        }
      }
      footerNav: allContentfulNavigation(
        filter: { identifier: { eq: "footer-navigation" } }
      ) {
        nodes {
          node_locale
          pages {
            __typename
            ... on ContentfulGenericPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
            ... on ContentfulExternalPage {
              titleNavigation
              fields {
                fullPath
                isExternal
              }
            }
          }
        }
      }
    }
  `)

  const headerItems = mainNav.nodes
    ?.find((node: INavNodeQuery) => {
      return node.node_locale == (pageContext.locale || defaultLang)
    })
    .pages?.filter((page: INavItemQuery) => {
      return page.fields?.fullPath
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        href: page.fields?.fullPath,
        isExternal: page.fields?.isExternal,
      }
    })

  const footerItems = footerNav.nodes
    ?.find((node: INavNodeQuery) => {
      return node.node_locale === (pageContext.locale || defaultLang)
    })
    .pages?.filter((page: INavItemQuery) => {
      return page.fields?.fullPath
    })
    .map((page: INavItemQuery) => {
      return {
        title: page.titleNavigation,
        href: page.fields?.fullPath,
        isExternal: page.fields?.isExternal,
      }
    })

  const [sffIntro, setSffIntro] = useState(false)
  const [sffIntroBefore, setSffIntroBefore] = useState(true)

  useEffect(() => {
    if (sessionStorage.getItem('sff-intro') !== 'ok') {
      if (navigator.userAgent && !isbot(navigator.userAgent)) {
        if (pageContext?.url == '/') {
          if (pageContext?.locale && pageContext?.page?.languages) {
            const userLang = browserLang({
              languages: availableLang,
              fallback: fallbackLang,
            })
            if (userLang !== (pageContext.locale || defaultLang)) {
              navigate(pageContext.page.languages[userLang])
            }
          }
        }
      }
      setSffIntro(true)
    } else {
      setSffIntroBefore(false)
    }
  }, [setSffIntro])

  const onCompleteHandler = () => {
    window.sessionStorage.setItem('sff-intro', 'ok')
    setSffIntro(false)
    setSffIntroBefore(false)
  }

  const onBeforeCompleteHandler = () => {
    setSffIntroBefore(false)
  }

  return (
    <div className={clsx(classes.layoutRoot)}>
      <MetaTags
        locale={pageContext.locale}
        meta={{ canonical: pageContext.canonical }}
      />

      <Header
        pageContext={pageContext}
        navItems={headerItems}
        hide={sffIntro || sffIntroBefore}
      />

      {sffIntro && (
        <Suspense fallback={<div className={classes.introFallback} />}>
          <IntroAnimation
            onBeforeComplete={onBeforeCompleteHandler}
            onComplete={onCompleteHandler}
          />
        </Suspense>
      )}

      <main
        className={clsx(classes.layoutMain, {
          [classes.layoutMainHidde]: sffIntroBefore,
        })}
      >
        <PageTransition>{children}</PageTransition>
      </main>

      <Footer navItems={footerItems} />
    </div>
  )
}
