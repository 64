import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { INavItemData } from '@components/navigation/drawerNav'
import { Grid, Typography } from '@material-ui/core'

import useGlobalText from '@system/hooks/useGlobalText'
import Paragraph from '@components/text/paragraph'
import SocialIcon from '@components/core/socialicon'
import Link from '@components/core/link'

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    position: 'relative',
    color: theme.palette.text.invert,
    background: theme.palette.background.focus,
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    zIndex: 40,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(12),
      paddingRight: theme.spacing(10),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(16),
      paddingLeft: theme.spacing(16),
    },
  },
  footerCopy: {
    marginBottom: theme.spacing(14),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  copyLink: {
    color: theme.palette.text.tertiary,
    '& > span::after': {
      bottom: '4px',
    },
    '&:hover': {
      color: 'inherit',
    },
  },
  footerNav: {
    alignItems: 'flex-start',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  copyright: {
    ...theme.typography.footernavigation,
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    },
  },
  nav: {
    ...theme.typography.footernavigation,
    flex: '1 0 auto',
  },
  navLink: {
    display: 'block',
    marginBottom: theme.spacing(8),
    color: 'inherit',
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
      marginBottom: 0,
      marginLeft: theme.spacing(10),
    },
    '& > span::after': {
      bottom: '-3px',
    },
  },
  social: {
    textAlign: 'right',
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  socialIcon: {
    display: 'inline-block',
    '&:not(:first-child)': {
      marginLeft: theme.spacing(8),
    },
  },
}))

export type FooterProps = {
  navItems: Array<INavItemData>
}

export default function Footer({ navItems }: FooterProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()

  return (
    <>
      <footer className={classes.footerRoot}>
        <div className={classes.footerCopy}>
          <Typography variant="h3" component="p">
            {getText('navigation.copy')}
          </Typography>
          <Paragraph>
            {getText('navigation.email.link') &&
              getText('navigation.email.label') && (
                <Link
                  to={getText('navigation.email.link')}
                  className={classes.copyLink}
                  isExternal={true}
                >
                  {getText('navigation.email.label')}
                </Link>
              )}
          </Paragraph>
        </div>
        <Grid
          container
          justifyContent="space-between"
          className={classes.footerNav}
        >
          <Grid item component="p" className={classes.copyright}>
            {getText('copyright')}
          </Grid>
          <Grid item className={classes.nav}>
            {navItems?.map((item, i) => {
              return (
                <Link
                  key={i}
                  className={classes.navLink}
                  to={item.href}
                  isExternal={item.isExternal}
                >
                  {item.title}
                </Link>
              )
            })}
          </Grid>
          <Grid item className={classes.social}>
            <SocialIcon
              item={{
                name: 'LinkedIn',
                href: getText('social.linkedin.link'),
              }}
              className={classes.socialIcon}
            />
            <SocialIcon
              item={{
                name: 'Twitter',
                href: getText('social.twitter.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
            <SocialIcon
              item={{
                name: 'Instagram',
                href: getText('social.instagram.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
            <SocialIcon
              item={{
                name: 'Spotify',
                href: getText('social.spotify.link'),
              }}
              className={clsx(classes.socialIcon)}
            />
          </Grid>
        </Grid>
      </footer>
    </>
  )
}
