import de from './de.json'
import en from './en.json'
import fr from './fr.json'

const messages: Record<string, Record<string, string>> = { de, en, fr }
const defaultLang = 'de'
const fallbackLang = 'en'
const availableLang = ['de', 'en', 'fr']

export { messages, defaultLang, fallbackLang, availableLang }
