import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

export type MetatagsProps = {
  meta: Partial<DBN.PageHelpers.PageContext['page']['metaTags']> & {
    canonical?: string | null
    noIndex?: boolean
  }
  locale?: string
}

export default function Metatags({
  meta,
  locale,
}: MetatagsProps): ReactElement {
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {meta?.metaTitle && <title>{meta.metaTitle}</title>}

      {meta?.metaDescription && (
        <meta name="description" content={meta.metaDescription} />
      )}
      {meta?.metaOgtitle && (
        <meta property="og:title" content={meta.metaOgtitle} />
      )}
      <meta name="twitter:card" content="summary" />
      {meta?.metaOgdescription && (
        <meta property="og:description" content={meta.metaOgdescription} />
      )}
      {meta?.metaOgimage && (
        <meta property="og:image" content={meta.metaOgimage} />
      )}
      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}
      {meta?.noIndex && <meta name="robots" content="noindex" />}

      {meta?.linkAlternates &&
        meta?.linkAlternates.map((lang) => (
          <link
            rel="alternate"
            hrefLang={lang.hreflang}
            href={lang.href}
            key={lang.hreflang}
          />
        ))}
    </Helmet>
  )
}
